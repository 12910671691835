<template>
  <div class="page">
    <div class="page-header">
      <!-- 选择大币种 -->
      <a-select
        :dropdownMatchSelectWidth="false"
        @select="changeVersionlistSearch"
        @search="getScreenInfo"
        :filter-option="untils.filterOption"
        allowClear
        showSearch
        size="small"
        class="header-item"
        v-model="params.coinName"
        placeholder="请选择大币种"
      >
        <a-select-option
          v-for="items of screenList"
          :key="items.coinName"
          :value="items.coinName"
        >{{ items.coinName  + (items.coinCategory ? `---${items.coinCategory}` : '' ) }}</a-select-option>
      </a-select>
      <!-- 选择版别 -->
      <a-select
        :dropdownMatchSelectWidth="false"
        @select="selectVersionSearch"
        allowClear
        showSearch
        :filter-option="untils.filterOption"
        size="small"
        class="header-item"
        v-model="params.versionName"
        placeholder="请选择版别"
      >
        <a-select-option
          v-for="items of versionList"
          :key="items.versionName"
          :value="items.versionName"
        >{{ `${items.versionName || ""}(${items.size || 0})` }}</a-select-option>
      </a-select>
      <a-button
        @click="search"
        size="small"
      >搜索</a-button>

      <a-button
        class="btn-item"
        size="small"
        type='primary'
        @click="uploadClick()"
      >上传</a-button>
    </div>
    <div class="page-body">
      <PageTable
        :list="list"
        :loading="loading"
        :pagination="pagination"
        @changePage="changePage"
        @success="getList"
      ></PageTable>
    </div>

    <ConfirmAdd
      ref="add"
      @success="addSuccess"
    ></ConfirmAdd>
  </div>
</template>

<script>
import PageTable from "@/views/cmsPage/versionManage/waitratingsplideranswer/PageTable.vue";
import ConfirmAdd from "@/views/cmsPage/versionManage/waitratingsplideranswer/ConfirmAdd.vue";
import untils from "@/untils";
import {getBigCoinList} from "@/axios/apis"

export default {
  data() {
    return {
      untils,
      list: [],
      loading: false,
      detail: "",
      params: {
        pageNum: 1,
        pageSize: 20,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      screenList: [],
      versionList: [],
    };
  },
  components: {
    PageTable,
    ConfirmAdd,
  },
  async mounted() {
    await this.getList();
  },
  methods: {
    addSuccess() {
      this.getList();
    },
    // 上传
    uploadClick() {
      this.$refs["add"].open();
    },
    async getScreenInfo(inner) {
      const res = await getBigCoinList(inner)
      if (res.status !== '200') return;
      this.screenList = res.data.records.map(el => ({
        coinName: el.coinKindName,
        coinId: el.id,
        coinCategory: el.coinCategory
      }));
    },
    // 选择大币种
    async changeVersionlistSearch(val) {
      const coin = this.screenList.find((el) => {
        return el.coinName == val;
      });
      delete this.params.versionName;
      this.versionList = coin.versionList;
    },
    // 选择版别
    selectVersionSearch() {
      this.search();
    },
    // 分页
    changePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },

    // 数据列表
    async getList() {
      this.loading = true;
      const res = await this.axios("/dq_admin/ratingSplider/list", {
        params: this.params,
      });
      this.loading = false;
      if (res.status != 200) return;
      this.pagination.total = res.data.total;
      this.list = res.data.records;
    },
    // 点击搜索按钮
    search() {
      this.pagination.current = 1;
      this.$set(this.params, "pageNum", 1);
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
}
.page-body {
  flex: 1;
  overflow: auto;
}
.page-header {
  display: flex;
  .header-item {
    margin-right: 5px;
    margin-bottom: 5px;
    width: 160px;
  }
}
.btn-item {
  margin-left: 12px;
}
</style>