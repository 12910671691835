var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "div",
        { staticClass: "page-header" },
        [
          _c(
            "a-select",
            {
              staticClass: "header-item",
              attrs: {
                dropdownMatchSelectWidth: false,
                "filter-option": _vm.untils.filterOption,
                allowClear: "",
                showSearch: "",
                size: "small",
                placeholder: "请选择大币种"
              },
              on: {
                select: _vm.changeVersionlistSearch,
                search: _vm.getScreenInfo
              },
              model: {
                value: _vm.params.coinName,
                callback: function($$v) {
                  _vm.$set(_vm.params, "coinName", $$v)
                },
                expression: "params.coinName"
              }
            },
            _vm._l(_vm.screenList, function(items) {
              return _c(
                "a-select-option",
                { key: items.coinName, attrs: { value: items.coinName } },
                [
                  _vm._v(
                    _vm._s(
                      items.coinName +
                        (items.coinCategory ? "---" + items.coinCategory : "")
                    )
                  )
                ]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticClass: "header-item",
              attrs: {
                dropdownMatchSelectWidth: false,
                allowClear: "",
                showSearch: "",
                "filter-option": _vm.untils.filterOption,
                size: "small",
                placeholder: "请选择版别"
              },
              on: { select: _vm.selectVersionSearch },
              model: {
                value: _vm.params.versionName,
                callback: function($$v) {
                  _vm.$set(_vm.params, "versionName", $$v)
                },
                expression: "params.versionName"
              }
            },
            _vm._l(_vm.versionList, function(items) {
              return _c(
                "a-select-option",
                { key: items.versionName, attrs: { value: items.versionName } },
                [
                  _vm._v(
                    _vm._s(
                      (items.versionName || "") + "(" + (items.size || 0) + ")"
                    )
                  )
                ]
              )
            }),
            1
          ),
          _c(
            "a-button",
            { attrs: { size: "small" }, on: { click: _vm.search } },
            [_vm._v("搜索")]
          ),
          _c(
            "a-button",
            {
              staticClass: "btn-item",
              attrs: { size: "small", type: "primary" },
              on: {
                click: function($event) {
                  return _vm.uploadClick()
                }
              }
            },
            [_vm._v("上传")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-body" },
        [
          _c("PageTable", {
            attrs: {
              list: _vm.list,
              loading: _vm.loading,
              pagination: _vm.pagination
            },
            on: { changePage: _vm.changePage, success: _vm.getList }
          })
        ],
        1
      ),
      _c("ConfirmAdd", { ref: "add", on: { success: _vm.addSuccess } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }